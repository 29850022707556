import { Box } from '@components'
import React from 'react'

type Props = {
  className?: string | undefined
  children: React.ReactNode
}

const SectionSeperator = ({ className = '', children }: Props) => {
  return (
    <Box tag='section' className={`${className} py-16`}>
      {children}
    </Box>

  )
}

export default SectionSeperator