import { Container, Paragraph, Span, Title } from '@components'
import React from 'react'
import SectionSeperator from '../layout/section-seperator'


const SavingFoodSection = () => {
  return (
    <SectionSeperator className='bg-[#33BC92] text-center py-24'>
      <Container>
        <Title tag='h4' className='text-4xl leading-[60px] font-bold mb-5'>
          <Span className='text-white'>
            START SAVING
          </Span>
          <br />
          <Span className='text-[#1C6162]'>
            DELICIOUS SURPLUS FOOD
          </Span>
        </Title>
        <Title tag='h6' className='text-2xl text-white mb-6'>
          AT GREAT PRICES!
        </Title>
        <Paragraph className='font-light !text-white text-lg leading-7'>
          We dream of a planet with no food waste, and every day we’re working on making that a reality. Our app is the most direct way for you to get involved - just download, log on, and get saving perfectly good, surplus food from your local stores. It’s always a surprise and an instant good deed for the planet. Get started now.
        </Paragraph>
      </Container>
    </SectionSeperator >

  )
}

export default SavingFoodSection